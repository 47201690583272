const requestApi = require("@/lib/request");
module.exports = {
    //获取商户信息
    storeInfo() {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/setting/store-info', {}).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //配置信息操作
    configForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/setting/config-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
}