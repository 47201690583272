const requestApi = require("@/lib/request");
//资源接口
module.exports = {
    //列表
    winList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/resource/win-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    }
}