<template>
    <div class="main_container">
        <div class="main_page">
            <div class="page_title">{{ $route.meta.title }}</div>
            <div class="page_containt" v-loading="loading">
                <el-form ref="modelForm" :rules="modelRules" :model="modelForm" label-width="120px" size="small"
                    class="form_box">

                    <el-form-item label="商户名称：" size="small" prop="name">
                        <el-input v-model="modelForm.name" placeholder="商户名称" style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="商户头像：" size="small" prop="face_path">
                        <el-button size="small" style="width: 100px" @click="showResource('face')">+选择图片</el-button>
                    </el-form-item>
                    <el-form-item label="" size="small">
                        <div class="more_img_box">
                            <div class="img_item one_img_item">
                                <el-image class="img" :src="
                                    modelForm.web_face_path ? modelForm.web_face_path : '/images/image-empty.png'
                                " fit="contain"></el-image>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="头部图片：" size="small" prop="banner_path">
                        <el-button size="small" style="width: 100px" @click="showResource('banner')">+选择图片</el-button>
                    </el-form-item>
                    <el-form-item label="" size="small">
                        <div class="more_img_box">
                            <div class="img_item one_img_item">
                                <el-image class="img" :src="
                                    modelForm.web_banner_path ? modelForm.web_banner_path : '/images/image-empty.png'
                                " fit="contain"></el-image>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" native-type="submit" @click.native.prevent="handleSubmit"
                            :loading="btnLogining">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <ResourceWin ref="ResourceWin" v-if="reVisible" :resType="1" :isMore="false" @closeResourceWin="reVisible = false"
            @setResources="setResources" />
    </div>
</template>
<script>
import settingApi from "@/api/setting";
import ResourceWin from "@/components/resource/win";
export default {
    components: {
        ResourceWin,
    },
    data() {
        return {
            loading: false,
            btnLogining: false,
            reVisible: false,
            modelForm: {
                name: "",
                web_banner_path: "",
                banner_path: "",
                web_face_path: "",
                face_path: ""
            },
            modelRules: {
                name: [
                    { required: true, message: "请输入", trigger: "blur" },
                    { min: 1, max: 100, message: "长度为100个字符" },
                ]
            },
        };
    },
    created() { },
    mounted() {
        this.loading = true;
        settingApi
            .storeInfo()
            .then((res) => {
                this.$nextTick(() => {
                    this.modelForm.name = res.model.name;
                    this.modelForm.web_banner_path = res.model.web_banner_path;
                    this.modelForm.banner_path = res.model.banner_path;
                    this.modelForm.web_face_path = res.model.web_face_path;
                    this.modelForm.face_path = res.model.face_path;
                });
                this.loading = false;
            })
            .catch((res) => {
            });
    },
    _resType: '',
    methods: {
        showResource(type) {
            this._resType = type;
            this.reVisible = true;
        },
        //设置资源回调方法
        setResources(data) {
            if (this._resType == 'face') {
                this.modelForm.face_path = data.files[0].path;
                this.modelForm.web_face_path = data.files[0].web_path;
            } else if (this._resType == 'banner') {
                this.modelForm.banner_path = data.files[0].path;
                this.modelForm.web_banner_path = data.files[0].web_path;
            }
            this.reVisible = false;
        },
        handleSubmit() {
            this.$refs.modelForm.validate((valid) => {
                if (valid) {
                    this.btnLogining = true;

                    settingApi
                        .configForm(this.modelForm)
                        .then((res) => {
                            this.btnLogining = false;
                            this.$message({
                                message: "提交成功",
                                type: "success",
                                offset: 60,
                            });
                        })
                        .catch((res) => {
                            this.btnLogining = false;
                        });
                } else {
                    return false;
                }
            });
        },
    },
};
</script>
        